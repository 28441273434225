import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import ResultsTitle from '../../components/results/ResultsTitle'
import '../../components/results/table-styles.css'
import { useReactToPrint } from 'react-to-print'

function SchoolRanking() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location.state.exam
    const form = location.state.form

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [results, setResults] = useState({})

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-Schools Ranking`,
        removeAfterPrint: true,
    })

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/results/analysis/${exam.id}/${form.id ? form.id : form}/school-rank`
            if (auth.user.role === "DSEO") {
                url += `?school=${exam.participants[0].id}`
            }

            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/analysis/${exam.id}/${form.id ? form.id : form}/school-rank`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setResults(data)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        handlePrint(null, () => contentRef.current)
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div className="mb-3">
                                <ResultsTitle
                                    exam={exam}
                                    form={form}
                                    school={results.school}
                                    title={"OVERALL SCHOOLS RANKING"}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                                        <thead>
                                            <tr className='align-middle'>
                                                <th rowSpan={3}>S/N</th>
                                                <th rowSpan={3}>NAME OF SCHOOL</th>
                                                <th rowSpan={3}>CENTRE NO</th>
                                                <th rowSpan={2} colSpan={3}>CANDIDATES SAT</th>
                                                <th colSpan={21}>RESULTS SUMMARY</th>
                                                <th rowSpan={3}>PASSING % (DIV I - DIV IV)</th>
                                                <th rowSpan={3}>PASSING AVERAGE</th>
                                                <th rowSpan={3}>GRADE</th>
                                                <th rowSpan={3}>GPA</th>
                                                <th rowSpan={3} className='vertical-text'>POSITION</th>
                                            </tr>
                                            <tr className='align-middle'>
                                                <th className='nowrap' colSpan={3}>DIV I</th>
                                                <th className='nowrap' colSpan={3}>DIV II</th>
                                                <th className='nowrap' colSpan={3}>DIV III</th>
                                                <th className='nowrap' colSpan={3}>DIV IV</th>
                                                <th className='nowrap' colSpan={3}>DIV 0</th>
                                                <th className='nowrap' colSpan={3}>INCOMPLETES</th>
                                                <th className='nowrap' colSpan={3}>ABSENTEES</th>
                                            </tr>
                                            <tr className='align-middle'>
                                                {
                                                    [...Array(8)].map((_, index) => (
                                                        <Fragment key={index}>
                                                            <th>F</th>
                                                            <th>M</th>
                                                            <th>T</th>
                                                        </Fragment>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                results.ranks && results.ranks.map((school, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className='text-start'>{school.name?.toUpperCase()}</td>
                                                        <td>{school.reg_no?.toUpperCase()}</td>
                                                        <td>{school.candidates && school.candidates["F"]}</td>
                                                        <td>{school.candidates && school.candidates["M"]}</td>
                                                        <td>{school.candidates && school.candidates["T"]}</td>
                                                        {
                                                            ["I", "II", "III", "IV", "0", "incomplete", "absent"].map((div, i) => (
                                                                <Fragment key={i}>
                                                                    <td>{school[div] && school[div]["F"]}</td>
                                                                    <td>{school[div] && school[div]["M"]}</td>
                                                                    <td>{school[div] && school[div]["T"]}</td>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <td>{school.percent}</td>
                                                        <td>{school.average}</td>
                                                        <td>{school.grade}</td>
                                                        <td>{school.gpa}</td>
                                                        <td>{school.position}/{results.ranks.length}</td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                results.bottom_summary &&
                                                <tr className="fw-bold">
                                                    <td colSpan={3} className='bg-info'>GRAND TOTAL</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["F"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["M"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["T"]}</td>
                                                    {
                                                        ["I", "II", "III", "IV", "0", "incomplete", "absent"].map((div, i) => (
                                                            <Fragment key={i}>
                                                                <td className='bg-info'>{results.bottom_summary[div]["F"]}</td>
                                                                <td className='bg-info'>{results.bottom_summary[div]["M"]}</td>
                                                                <td className='bg-info'>{results.bottom_summary[div]["T"]}</td>
                                                            </Fragment>
                                                        ))
                                                    }
                                                    <td className='bg-info'>{results.bottom_summary["percent"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["average"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["grade"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["gpa"]}</td>
                                                    <td className='bg-info'></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default SchoolRanking
