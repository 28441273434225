import React, { useEffect, useRef, useState } from 'react'
import Loading from '../../components/loading/Loading'
import ResultsTitle from '../../components/results/ResultsTitle'
import TopNStudents from '../../components/results/TopNStudents'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'
import { useReactToPrint } from 'react-to-print'

function OverallTenStudents() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location.state.exam
    const form = location.state.form

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const headers = [
        {
            name: "INDEX NUMBER",
            key: "index_number",
            align: "center"
        },
        {
            name: "NAME OF CANDIDATE",
            key: "name",
            align: "start"
        },
        {
            name: "SEX",
            key: "sex",
            align: "center"
        },
        {
            name: "SCHOOL",
            key: "school",
            align: "start"
        },
        {
            name: "AVERAGE",
            key: "average",
            align: "center"
        },
        {
            name: "GRADE",
            key: "grade",
            align: "center"
        },
        {
            name: "GPA",
            key: "gpa",
            align: "center"
        },
        {
            name: "POSITION",
            key: "position",
            align: "center"
        }
    ]

    const [school, setSchool] = useState(null)
    const [results, setResults] = useState({})

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-Overall Ten Students`,
        removeAfterPrint: true,
    })

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/results/analysis/${exam.id}/${form.id ? form.id : form}/best`
            if (auth.user.role === "DSEO") {
                url += `?school=${exam.participants[0].id}`
            }

            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/analysis/${exam.id}/${form.id ? form.id : form}/best`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setResults(data.best_performers)
                setSchool(data.school)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        handlePrint(null, () => contentRef.current)
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div className="mb-3">
                                <ResultsTitle exam={exam} form={form} school={school} title={"OVERALL TEN STUDENTS"} />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"1. TEN BEST BOYS"}
                                    headers={headers}
                                    students={results.best_male ? results.best_male : []}
                                />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"2. TEN BEST GIRLS"}
                                    headers={headers}
                                    students={results.best_female ? results.best_female : []}
                                />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"3. OVERALL BEST STUDENTS"}
                                    headers={headers}
                                    students={results.best_overall ? results.best_overall : []}
                                />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"4. TEN WORST BOYS"}
                                    headers={headers}
                                    students={results.worst_male ? results.worst_male : []}
                                />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"5. TEN WORST GIRLS"}
                                    headers={headers}
                                    students={results.worst_female ? results.worst_female : []}
                                />
                            </div>
                            <div className="mb-3">
                                <TopNStudents
                                    title={"6. OVERALL WORST STUDENTS"}
                                    headers={headers}
                                    students={results.worst_overall ? results.worst_overall : []}
                                />
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default OverallTenStudents
