import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'

function StaffSchool() {
    const auth = useAuth()
    const location = useLocation()
    const school = location.state.school

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('testing message')
    const [variant, setVariant] = useState('success')

    const [staffList, setStaffList] = useState([])
    const [staffListResult, setStaffListResult] = useState([])
    // selected values of staff members
    const [staffs, setStaffs] = useState([])

    const fetchStaff = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/unallocated`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setStaffList(data.employees)
                setStaffListResult(data.employees)
            }
        }
        catch (error) {
            setMessage('Failed to fetch staff. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSearch = async (value) => {
        setMessage('')
        if (value.length < 4) {
            setStaffListResult(staffList)
        }
        else {
            try {
                const response = await fetch(`${BASE_API_URL}/employees/search?q=${value}`, {
                    headers: {
                        'x-access-token': auth.token
                    }
                })
                const data = await response.json()

                if (!response.ok) {
                    setMessage(data.message)
                    setVariant('warning')
                }
                else {
                    setStaffListResult(data.employees)
                }
            }
            catch (error) {
                setMessage('Failed to search staff. Please try again.')
                setVariant('warning')
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage('')
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${school.id}/staff/assign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ staffs })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setStaffs([])
                fetchStaff()
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('Failed to assign staff members. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                Assign School Staff
                <br />
                <small className='fs-6'>
                    NOTE: Only staff members that are not assigned to any school will be displayed.
                    To transfer a staff member that is already in a school, go to&nbsp;
                    <Link to='/staffs' className='text-primary'>transfers</Link>
                </small>
            </p>

            <div className="mb-4">
                <p>
                    <span className='fw-bold me-2'>Name:</span>
                    <span className='text-muted fs-5'>
                        {school.name} Secondary School
                    </span>
                </p>
                <p>
                    <span className='fw-bold me-2'>Registration Number:</span>
                    <span className='text-muted fs-5'>
                        {school.reg_no}
                    </span>
                </p>
                <p>
                    <span className='fw-bold me-2'>Current Headmaster:</span>
                    {
                        school.headmaster ?
                            <Link to={`/staffs/${school.headmaster.id}`} className='fs-5'>
                                {school.headmaster.firstname} {school.headmaster.lastname}
                            </Link>
                            :
                            <span className='text-danger'>None Assigned</span>
                    }
                </p>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        <Loading />
                    </div>
                    :
                    (
                        <div className='mb-4'>
                            <p className="h4 fw-bold text-muted mb-1">
                                Select all staff members to assign to this school
                            </p>
                            {/* search for staff members */}
                            <div className="body shadow p-2 mb-3 d-flex">
                                <input
                                    type="text"
                                    className="form-control me-1 rounded-0"
                                    placeholder="Search schools..."
                                    onChange={e => handleSearch(e.target.value)}
                                />
                                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                                    Search
                                </button>
                            </div>

                            {/* actual form to select staff */}
                            <form onSubmit={handleSubmit}>
                                <div className="row mx-1">
                                    {
                                        staffListResult.length > 0 ?
                                            staffListResult && staffListResult.map((staff, index) => (
                                                <div key={index} className="col-12 col-lg-auto m-2 p-2 border rounded-0">
                                                    <input
                                                        type="checkbox"
                                                        name="staffs"
                                                        id={staff.id}
                                                        className='form-radio'
                                                        checked={staffs.includes(staff.id)}
                                                        value={staff.id}
                                                        onChange={e => {
                                                            if (e.target.checked) {
                                                                setStaffs([...staffs, staff.id])
                                                            }
                                                            else {
                                                                setStaffs(staffs.filter(staffId => staffId !== staff.id))
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={staff.id} className='ms-2 form-label'>
                                                        {staff.firstname} {staff.lastname}
                                                    </label>
                                                </div>
                                            )) :
                                            <div className="text-center text-secondary">
                                                All staff members are already assigned to schools
                                            </div>
                                    }
                                </div>
                                <div className="text-end">
                                    <button type="submit" className='btn btn-primary fw-bold rounded-0 px-4'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )
            }
        </div>
    )
}

export default StaffSchool
