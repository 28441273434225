import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DivisionPerformance from '../../components/results/DivisionPerformance'
import SubjectPerformance from '../../components/results/SubjectPerformance'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'

function ExternalResults() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [exam, setExam] = useState(location.state.exam)
    const [results, setResults] = useState({})
    const [form, setForm] = useState(sessionStorage.getItem("selectedForm") || "Form I")
    const [participants, setParticipants] = useState([])
    // const [filterSchool, setFilterSchool] = useState('')

    const fetchExam = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${exam_id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setExam(data.exam)
                setParticipants(data.exam.participants)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/results/external/overview/${exam_id}/${form}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setResults(data.results)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExam()
    }, [])

    useEffect(() => {
        fetchResults()
        sessionStorage.setItem("selectedForm", form)
    }, [form])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>{exam.name}</p>
            <div className="body shadow p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam.end_date}</span>
                    </p>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    (
                        <div className='text-center my-2'>
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <>
                            <div className="body shadow p-2 mb-3">
                                <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                    <span className="h4 fw-bold text-muted me-3 mb-2">Choose Class</span>
                                    <div className='flex-grow-1'>
                                        <select
                                            className='form-select rounded-0'
                                            value={form}
                                            onChange={e => setForm(e.target.value)}
                                        >
                                            <option value='Form I'>Form 1</option>
                                            <option value='Form II'>Form 2</option>
                                            <option value='Form III'>Form 3</option>
                                            <option value='Form IV'>Form 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="body shadow p-2 mb-3">
                                {
                                    results.division_performance && <DivisionPerformance summary={results.division_performance} />
                                }
                                {
                                    results.subject_performance && <SubjectPerformance summary={results.subject_performance} />
                                }
                            </div>

                            <div className="body shadow p-2 mb-3">
                                <div className="d-flex flex-wrap align-items-center jutify-content-between">
                                    <Link
                                        to={`${form}/school-ranks`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Schools Ranking
                                    </Link>
                                    <Link
                                        to={`${form}/subject-ranks`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Subjects Ranking
                                    </Link>
                                    <Link
                                        to={`${form}/teacher-ranks`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Overall Teachers Ranking
                                    </Link>
                                    <Link
                                        to={`${form}/overall-ten`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Overall Ten Students
                                    </Link>
                                    <Link
                                        to={`${form}/subjectwise`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Overall Ten Students Subjectwise
                                    </Link>
                                    <Link
                                        to={`${form}/subjectwise-teachers`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        Teachers' Ranking Subjectwise
                                    </Link>
                                    <Link
                                        to={`${form}/overall-ranks`}
                                        state={{ exam: exam, form: form }}
                                        className='fw-bold me-4 mb-2'>
                                        General schools' Results
                                    </Link>
                                    <Link
                                        to={`${form}/reports`}
                                        state={{ exam: exam, form: `${form}` }}
                                        className='fw-bold me-4 mb-2'>
                                        Exam Report Forms
                                    </Link>
                                </div>
                            </div>

                            <div className="body shadow p-2 mb-3">
                                <p className='h4 fw-bold text-muted'>Specific School Results</p>
                                {
                                    exam.participants &&
                                    <p className='h5'>
                                        Schools Participated: {exam.participants.length}
                                    </p>
                                }
                                <div className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                                    <div className='flex-grow-1 me-2'>
                                        <input
                                            type='text'
                                            className='form-control rounded-0'
                                            placeholder='Search School'
                                            onChange={e => {
                                                let data = exam.participants.filter(participant => participant.name.toLowerCase().includes(e.target.value.toLowerCase()))
                                                setParticipants(data)
                                            }}
                                        />
                                    </div>
                                    <div className='text-end'>
                                        <button className='btn btn-primary rounded-0 fw-bold'>
                                            Search
                                        </button>
                                    </div>
                                </div>

                                {
                                    participants &&
                                    <DataTable
                                        columns={[
                                            {
                                                name: 'S/N',
                                                cell: (row, index) => index + 1,
                                                grow: 0
                                            },
                                            {
                                                name: 'School Name',
                                                selector: row => row.name,
                                                sortable: true,
                                                grow: 2
                                            },
                                            {
                                                name: 'Registration Number',
                                                selector: row => row.reg_no,
                                                sortable: true,
                                                grow: 2
                                            },
                                            {
                                                name: 'Action',
                                                cell: row => <Link
                                                    to={`${form}`}
                                                    state={{ exam: exam, form: form, school: row }}
                                                    // if not dseo (i.e has a school), then enable only access to their schools results
                                                    // className={`btn btn-sm btn-primary rounded-0 ${auth.user.school?auth.user.school.id==row.id?'':'disabled':''}`}>
                                                    className={`btn btn-sm btn-primary rounded-0`}>
                                                    View
                                                </Link>,
                                                sortable: false
                                            }
                                        ]}
                                        data={participants}
                                        responsive
                                        noDataComponent="No schools found."
                                    />
                                }
                            </div>
                        </>
                    )
            }
        </div>
    )
}

export default ExternalResults
