import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'

function ReportDetails() {
    const auth = useAuth()
    const params = useParams()
    const location = useLocation()
    const examId = params.exam_id
    const exam = location.state.exam

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [details, setDetails] = useState({})

    const fetchDetails = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/report/${examId}/details`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setDetails(data.details)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Exam Report Details</p>
                <Link
                    to={`/academics/exams/${examId}/report/new`}
                    state={{ exam }}
                    className={`btn btn-primary btn-sm fw-bold rounded-0 mb-2 me-2 ${isLoading ? 'disabled' : ''}`}
                >
                    <span className="me-2">New</span>
                    <i className='bi bi-plus-circle'></i>
                </Link>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="body shadow p-2 mb-3">
                {
                    isLoading ?
                        <div className="text-center">Loading... <Loading /></div>
                        :
                        <>
                            {
                                !details ?
                                    <div className="text-center">No exam report details present</div>
                                    :
                                    <div>
                                        <p className='text-muted'>
                                            Current Semester: <span className='fw-bold'>{details.current_semester}</span>
                                        </p>
                                        <p className='text-muted'>
                                            Opening Semester: <span className='fw-bold'>{details.opening_semester}</span>
                                        </p>
                                        <p className='text-muted'>
                                            Opening Date: <span className='fw-bold'>{details.opening_date}</span>
                                        </p>
                                        <p className='text-muted'>
                                            Opening Time: <span className='fw-bold'>{details.opening_time}</span>
                                        </p>
                                        <p className='text-muted'>
                                            Class Teacher's Opinion: <span className='fw-bold'>{details.class_teachers_opinion}</span>
                                        </p>
                                        <p className='text-muted'>
                                            Headmaster's Opinion: <span className='fw-bold'>{details.headmasters_opinion}</span>
                                        </p>
                                    </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default ReportDetails
