import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'

function ExamCandidates({ hideOptions }) {
    const auth = useAuth()
    const params = useParams()
    const location = useLocation()
    const examId = params.exam_id
    const form = location.state.form

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [errors, setErrors] = useState([])
    const [showDialog, setShowDialog] = useState(false)

    const [candidates, setCandidates] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])
    const [toDelete, setToDelete] = useState(null)

    const candidates_columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Index No',
            selector: row => row.index_number,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Subjects',
            selector: row => row.subjects,
            sortable: true,
            grow: 0,
        },
        auth.user.role !== "DSEO" &&
        {
            name:
                <RoleOffice roles={["Headmaster", "Academic Master", form]} element={
                    <div>
                        <input
                            type='checkbox'
                            className='me-1'
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedStudents(candidates.map(s => s.candidate_id))
                                } else {
                                    setSelectedStudents([])
                                }
                            }}
                        />
                        <span>({selectedStudents.length})</span>
                    </div>
                } />,
            cell: row =>
                <RoleOffice roles={["Headmaster", "Academic Master", form]} element={
                    <div className='d-flex flex-wrap align-items-center'>
                        <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                            !hideOptions &&
                            <button
                                className='btn btn-sm btn-danger rounded-0 me-1 mb-1'
                                onClick={() => {
                                    setToDelete(row)
                                    setShowDialog(true)
                                }}>
                                <i className='bi bi-trash-fill fs-6 mx-1'></i>
                            </button>
                        } />
                        <button
                            type='button'
                            className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.candidate_id) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => {
                                if (selectedStudents.includes(row.candidate_id)) {
                                    setSelectedStudents(selectedStudents.filter(studentId => studentId !== row.candidate_id))
                                } else {
                                    setSelectedStudents([...selectedStudents, row.candidate_id])
                                }
                            }}>
                            {selectedStudents.includes(row.candidate_id) ? 'Selected' : 'Select'}
                        </button>
                    </div>
                } />,
            sortable: false,
        }
    ]

    const fetchCandidates = async () => {
        setIsLoading(true)

        try {
            // per_page=10000, an arbitrary large number to fetch all candidates at once
            const response = await fetch(`${BASE_API_URL}/exams/${examId}/candidates?page=1&per_page=10000&form=${form}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setCandidates(data.candidates)
            }
        }
        catch (error) {
            setMessage('Failed to fetch candidates. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const deleteCandidates = async () => {
        setIsLoading(true)
        setMessage('')
        setErrors([])

        let students = []
        if (toDelete && toDelete.candidate_id) {
            students = [toDelete.candidate_id]
        }
        else {
            students = selectedStudents
        }

        if (students.length < 1) {
            setMessage("Select at least one candidate to delete")
            setVariant("warning")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${examId}/candidates`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "candidates": students
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
                setSelectedStudents([])
                fetchCandidates()
            }
        }
        catch (error) {
            setMessage('Failed to deregister student. Please try again.')
            setVariant('danger')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    const downloadReports = async () => {
        setIsLoading(true)
        setMessage('')
        setErrors([])

        if (selectedStudents.length < 1) {
            setMessage("Select at least one candidate to download reports")
            setVariant("warning")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/report/${examId}/${form}/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "candidates": selectedStudents
                })
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }
            let filename = `${suff}-Report Form.pdf`
            const contentDisposition = response.headers.get('Content-Disposition')

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="(.+)"/)
                if (filenameMatch.length === 2) {
                    filename = filenameMatch[1]
                }
            }
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to download student report. Please try again.')
            setVariant('danger')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    const downloadCAL = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${examId}/cal/${form}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }
            const filename = `${suff}-${form}-CAL.xlsx`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to download CAL. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchCandidates()
    }, [])

    return (
        <div className='pt-2'>
            <div className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                <span className="h3 fw-bold text-muted">{form} Candidates</span>
                <div className='d-flex flex-wrap align-items-center justify-content-between'>
                    <RoleOffice
                        roles={["Headmaster", "Academic Master"]}
                        element={
                            !hideOptions &&
                            <>
                                <button
                                    className='btn btn-primary btn-sm rounded-0 fw-bold me-2'
                                    onClick={downloadCAL}
                                    disabled={isLoading}
                                >
                                    <span className="me-2">Download CAL</span>
                                    <i className='bi bi-download'></i>
                                </button>
                                <Link
                                    to={'register'}
                                    state={{ form: form }}
                                    className='btn btn-primary btn-sm fw-bold rounded-0'
                                >
                                    <span className="me-2">Register Candidates</span>
                                    <i className='bi bi-person-fill-add'></i>
                                </Link>
                            </>
                        }
                    />
                </div>
            </div>

            <div className="mb-3">
                {
                    message &&
                    <>
                        <div className={`alert alert-${variant} p-2 mb-2`} role="alert">
                            {message}
                        </div>
                        <div>
                            {
                                errors && errors.map((error, index) => (
                                    <div className="alert alert-warning p-2 mb-1" key={index}>
                                        {error}
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setToDelete({})
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    deleteCandidates()
                    setShowDialog(false)
                }}
                isLoading={isLoading}
                title="Deregister Student"
                message={
                    toDelete && toDelete.id ?
                        `Are you sure you want to delete candidate ${toDelete.firstname} ${toDelete.lastname}?` :
                        `Are you sure you want to delete ${selectedStudents.length} candidates?`
                }
            />

            <div className="shadow p-2 mb-3">
                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>{candidates ? candidates.length : 0} Candidates</span>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <RoleOffice
                                    roles={["Headmaster", "Academic Master"]}
                                    element={
                                        !hideOptions &&
                                        <button
                                            className='btn btn-sm btn-danger rounded-0 me-2'
                                            disabled={selectedStudents.length < 1 || isLoading}
                                            onClick={() => {
                                                setToDelete({})
                                                setShowDialog(true)
                                            }}
                                        >
                                            <span className="me-2">Delete Selected</span>
                                            <i className='bi bi-trash'></i>
                                        </button>
                                    }
                                />
                                <RoleOffice roles={["Headmaster", "Academic Master", form]} element={
                                    <button
                                        className='btn btn-sm btn-primary rounded-0'
                                        disabled={selectedStudents.length < 1 || isLoading}
                                        onClick={() => {
                                            if (selectedStudents.length > 50) {
                                                setMessage("Currently you can only download report forms of at most 50 students at a time.")
                                                setVariant("warning")
                                            }
                                            else {
                                                downloadReports()
                                            }
                                        }}
                                    >
                                        <span className="me-2">Download Report</span>
                                        <i className='bi bi-file-earmark-arrow-down-fill'></i>
                                    </button>
                                } />
                            </div>
                        </div>
                    }
                    columns={candidates_columns}
                    data={candidates}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={"No candidates found."}
                />
            </div>
        </div>
    )
}

export default ExamCandidates
