import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import DivisionPerformance from '../../components/results/DivisionPerformance'
import SubjectPerformance from '../../components/results/SubjectPerformance'
import StudentPerformance from '../../components/results/StudentPerformance'
import ResultsTitle from '../../components/results/ResultsTitle'
import { useReactToPrint } from 'react-to-print'

function OverallResults() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id
    const location = useLocation()

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [exam, setExam] = useState(location.state.exam)
    const [form, setForm] = useState(location.state.form)
    const [school, setSchool] = useState(location.state.school)
    const [results, setResults] = useState({})

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-School Results`,
        removeAfterPrint: true,
    })

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/results/analysis/${exam_id}/${form.id ? form.id : form}`
            if (auth.user.role === "DSEO") {
                url += `?school=${exam.participants[0].id}`
            }

            // school is only passed for external exams
            if (school) {
                url = `${BASE_API_URL}/results/external/analysis/${exam_id}/${form.id ? form.id : form}/${school.id}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setResults(data.results)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        handlePrint(null, () => contentRef.current)
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <>
                            {
                                results.division_performance && results.subject_performance && results.student_ranking ?
                                    (
                                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                                            <div className="mb-3">
                                                <ResultsTitle exam={exam} form={form} school={results.school} />
                                            </div>
                                            <div className="mb-3">
                                                <DivisionPerformance summary={results.division_performance} />
                                            </div>
                                            <div className="mb-3">
                                                <SubjectPerformance summary={results.subject_performance} />
                                            </div>
                                            <div className="mb-3">
                                                <StudentPerformance summary={results.student_ranking} />
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                message &&
                                                <div className={`alert alert-${variant} p-2`} role="alert">
                                                    {message}
                                                </div>
                                            }
                                        </>
                                    )
                            }
                        </>
                    )
            }
        </div>
    )
}

export default OverallResults
