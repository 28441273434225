import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function ResultsTitle({ exam, form, school, main_authority, sub_authoruty, region, district, display, title }) {
    // if display then dont fetch data from server, the component has been called only to display data
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const month = Number(exam.start_date.split('/')[1])
    const month_str = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const year = exam.start_date.split('/')[2]
    const sub_title = `${form.name || ''} ${exam.name} RESULTS, ${month_str[month - 1]}-${year}`.toUpperCase()

    const [mainAuthority, setMainAuthority] = useState(main_authority || "PRESIDENT'S OFFICE")
    const [subAuthority, setSubAuthority] = useState(sub_authoruty || "REGIONAL ADMINISTRATION AND LOCAL GOVERNMENT AUTHORITY")
    const [reg, setReg] = useState(region || "SIMIYU")
    const [dist, setDist] = useState(district || "MEATU")

    const fetchExamResultsTitle = async () => {
        if (display) {
            return
        }

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/results/title`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMainAuthority(data.title.main_authority)
                setSubAuthority(data.title.sub_authority)
                setReg(data.title.region)
                setDist(data.title.district)
            }
        } catch (error) {
            setMessage('An error occurred. Please try again')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!display) {
            fetchExamResultsTitle()
        }
        else {
            setMainAuthority(main_authority)
            setSubAuthority(sub_authoruty)
            setReg(region)
            setDist(district)
        }
    }, [main_authority, sub_authoruty, region, district])

    return (
        <div className='row' style={{fontFamily: "Times New Roman", color: "#800080"}}>
            <div className='col-12 text-center'>
                <h4 className='fw-bold'>{mainAuthority.toUpperCase() || "PRESIDENT'S OFFICE"}</h4>
                <h4 className="fw-bold">{subAuthority.toUpperCase() || "REGIONAL ADMINISTRATION AND LOCAL GOVERNMENT AUTHORITY"}</h4>
                <h4 className="fw-bold">{`${reg} REGION`.toUpperCase()}</h4>
                <h4 className='fw-bold'>{`${dist} DISTRICT COUNCIL`.toUpperCase()}</h4>
                <h4 className='fw-bold'>{sub_title.toUpperCase()}</h4>
                {
                    school &&
                    <h4>
                        <strong>CENTRE NO: </strong>{school.reg_no}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>CENTRE NAME: </strong>{school.name.toUpperCase()} SECONDARY SCHOOL
                    </h4>
                }
                {
                    title && <h4 className='fw-bold'>{title.toUpperCase()}</h4>
                }
            </div>
        </div>
    )
}

export default ResultsTitle
