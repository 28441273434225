import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function NewSubject() {
    const auth = useAuth()
    const user = auth.user

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [name, setName] = useState('')
    const [code, setCode] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/bare`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    code
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to register subject. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register Subject</p>
            <div className='shadow p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name'>Subject Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='code'>Subject Code</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='code'
                                    required
                                    disabled={isLoading}
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSubject
