import React, { useEffect, useState } from 'react'
import SingleSubjectTopicCoverage from '../../../components/topic-coverage/SingleSubjectTopicCoverage'
import SchoolSubjectTopicCoverage from '../../../components/topic-coverage/SchoolSubjectTopicCoverage'
import DistrictSubjectTopicCoverage from '../../../components/topic-coverage/DistrictSubjectTopicCoverage'
import { useAuth } from '../../../context/AuthContext'
import RoleOffice from '../../../components/offices/RoleOffice'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import { useLocation } from 'react-router-dom'

function TopicCoverageReports({ type }) {
    const auth = useAuth()
    const location = useLocation()
    const form = location?.state?.form
    const subject = location?.state?.subject
    const school = location?.state?.school

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    // if user is not dseo, default to school type
    if (type === "district" && !["DSEO"].includes(auth.user.role)) {
        type = "school"
    }

    const [selectedType, setSelectedType] = useState(type)
    const [schools, setSchools] = useState([])
    const [selectedSchool, setSelectedSchool] = useState("")
    const [coverage, setCoverage] = useState([])

    const fetchSchools = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            setMessage('Failed to fetch schools. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchReport = async () => {
        setIsLoading(true)
        setMessage("")
        setCoverage(null)

        try {
            let url = `${BASE_API_URL}/subject-topic/report/${selectedType}`

            if (selectedType === "school" && ["DSEO"].includes(auth.user.role)) {
                url += `?school=${selectedSchool}`
            }
            else if (selectedType === "subject") {
                url += `?form=${form.id}&subject=${subject.id}&school=${school.id}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setCoverage(data.coverage)
            }
        }
        catch (error) {
            setMessage('Failed to fetch report. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (selectedType === "school" && ["DSEO"].includes(auth.user.role)) {
            fetchSchools()
        }

        fetchReport()
    }, [selectedType])

    useEffect(() => {
        if (selectedSchool) {
            fetchReport()
        }
        else {
            setCoverage([])
        }
    }, [selectedSchool])

    useEffect(() => {
        fetchReport()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Subject Topic Coverage Report</p>

            {
                selectedType !== "subject" &&
                <RoleOffice roles={["DSEO"]} element={
                    <>
                        <div className='mb-3 form-group'>
                            <label htmlFor="type" className='form-label'>Choose Report Type</label>
                            <select
                                className='form-select rounded-0'
                                id='type'
                                disabled={isLoading}
                                value={selectedType}
                                onChange={e => setSelectedType(e.target.value)}
                            >
                                <option value={"district"}>District Topic Coverage</option>
                                <option value={"school"}>School Topic Coverage</option>
                            </select>
                        </div>

                        {
                            selectedType === "school" &&
                            <div className='mb-3 form-group'>
                                <label htmlFor="type" className='form-label'>Choose School</label>
                                <select
                                    className='form-select rounded-0'
                                    id='type'
                                    required
                                    disabled={isLoading}
                                    value={selectedSchool}
                                    onChange={e => setSelectedSchool(e.target.value)}
                                >
                                    <option value="">Choose School</option>
                                    {
                                        schools.map((school, index) =>
                                            <option key={index} value={school.id}>{school.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        }
                    </>
                } />
            }

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="shadow p-2 mb-3 rounded-0">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        coverage &&
                        <>
                            {
                                selectedType === "subject" &&
                                <div>
                                    <SingleSubjectTopicCoverage coverage={coverage} />
                                </div>
                            }
                            {
                                selectedType === "school" && (selectedSchool || !["DSEO"].includes(auth.user.role)) &&
                                <div>
                                    <SchoolSubjectTopicCoverage coverage={coverage} />
                                </div>
                            }
                            {
                                selectedType === "district" &&
                                <div>
                                    <DistrictSubjectTopicCoverage coverage={coverage} />
                                </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default TopicCoverageReports
