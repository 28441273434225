import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'

function SubjectTeachers() {
    const auth = useAuth()
    const user = auth.user
    const location = useLocation()
    const subject = location.state.subject

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [staffs, setStaffs] = useState([])
    const [classes, setClasses] = useState([])
    const [selections, setSelections] = useState([])

    const fetchClasses = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setClasses(data.classes)
            }
        }
        catch (error) {
            setMessage('Failed to fetch classes. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStaff = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/core-subjects/${subject.id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setStaffs(data.staffs)
            }
        }
        catch (error) {
            setMessage('Failed to fetch staffs. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage('')
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/${subject.id}/teachers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ selections })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('Failed to assign teachers to subject. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff()
        fetchClasses()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                Assign Subject Teachers
            </p>

            <div className="mb-4 p-2 shadow rounded-0">
                <p>
                    <span className='fw-bold me-2'>Name:</span>
                    <span className='text-muted fs-5'>
                        {subject.name}
                    </span>
                </p>
                <p>
                    <span className='fw-bold me-2'>Code:</span>
                    <span className='text-muted fs-5'>
                        {subject.code}
                    </span>
                </p>
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        <Loading />
                    </div>
                    :
                    (
                        <div className='shadow p-2 mb-4'>
                            <p className="h4 fw-bold text-muted mb-3">
                                Select teachers to assign to this subject
                            </p>

                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <div className="row">
                                        {
                                            classes.map(cls => (
                                                <div className="col-lg-3 mb-3" key={cls.id}>
                                                    <label htmlFor={`class-${cls.id}`} className="fw-bold">
                                                        {cls.name}
                                                    </label>
                                                    <select
                                                        name={`form${cls.value}`}
                                                        id={`class-${cls.id}`}
                                                        className="form-select rounded-0"
                                                        onChange={e => {
                                                            const value = e.target.value
                                                            const form = cls.id
                                                            const s = { form, value }
                                                            if (selections.some(sel => sel.form === form)) {
                                                                setSelections(selections.map(sel => {
                                                                    if (sel.form === form) {
                                                                        return s
                                                                    }
                                                                    return sel
                                                                }))
                                                            }
                                                            else {
                                                                setSelections([...selections, s])
                                                            }
                                                        }}
                                                    >
                                                        <option value="">Select teacher</option>
                                                        {
                                                            staffs.map(staff => (
                                                                <option key={staff.id} value={staff.id}>
                                                                    {staff.firstname} {staff.lastname}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="submit" className='btn btn-primary fw-bold rounded-0 px-4'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )
            }
        </div>
    )
}

export default SubjectTeachers
