import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function Grades() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [grades, setGrades] = useState([])

    const fetchGrades = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/grades`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setGrades(data.grades)
                setMessage("")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchGrades()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>New Grading System</p>
                    <p className='text-muted'>All examination scores uploaded will use these grades if they dont have their own grades set.</p>
                </div>
                <RoleOffice roles={["DSEO", "Headmaster", "Academic Master"]} element={
                    <div>
                        <Link
                            to={`/academics/grades/${grades.id}/edit`}
                            state={{ grades }}
                            className='btn btn-primary btn-sm fw-bold rounded-0 me-2 mb-1 px-3'
                        >
                            <span className="me-2">Edit</span>
                            <i className='bi bi-pencil-square'></i>
                        </Link>
                        <Link to='/academics/grades/new' className='btn btn-primary btn-sm fw-bold rounded-0 mb-1'>
                            <span className="me-2">New Grades</span>
                            <i className='bi bi-plus-square-fill'></i>
                        </Link>
                    </div>
                }
                />
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <div className='row mx-0'>
                            {
                                grades.map(grade => (
                                    <div key={grade.id} className="col-12 col-lg-3 m-2 px-3 py-2 border rounded-0">
                                        <p className='fs-3 fw-bold'>{grade.grade} ({grade.points})</p>
                                        <div className='fs-5 fw-bold d-flex align-items-center justify-content-between'>
                                            <span className='me-2'>Min Score: {grade.min_score}</span>
                                            <span className={`badge bg-${grade.indicator} rounded-0`}>
                                                {grade.remarks}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default Grades
