import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { Link } from 'react-router-dom'

function TeachingClasses() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [classes, setClasses] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Class Name',
            selector: row => row.form.name,
            sortable: true
        },
        {
            name: 'Subject Name',
            selector: row => row.subject.name,
            sortable: true
        },
        {
            name: 'Date Started',
            selector: row => row.date_started,
            sortable: true
        },
        {
            name: 'Date Ended',
            selector: row => row.date_ended,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`/teaching/classes/students`}
                    state={{ teachingClass: row }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/teaching/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setClasses(data.classes)
            }
        }
        catch (error) {
            setMessage('Failed to fetch classes. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Classes You Teach</p>
            </div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={classes}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            noDataComponent={"You dont teach any classes."}
                        />
                }
            </div>
        </div>
    )
}

export default TeachingClasses
