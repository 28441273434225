import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { convertDateFormat } from '../../utils/date_conversions'
import RoleOffice from '../../components/offices/RoleOffice'

function NewStaff() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [phoneError, setPhoneError] = useState(false)
    const [errorsFile, setErrorsFile] = useState()

    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [sex, setSex] = useState('')
    const [checkNumber, setCheckNumber] = useState('')
    const [tscNumber, setTscNumber] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dob, setDob] = useState('')
    const [educationLevel, setEducationLevel] = useState('')
    const [salaryLevel, setSalaryLevel] = useState('')
    const [subjectOne, setSubjectOne] = useState('')
    const [subjectTwo, setSubjectTwo] = useState('')
    const [subjectThree, setSubjectThree] = useState('')
    const [subjectFour, setSubjecFour] = useState('')
    const [employmentDate, setEmploymentDate] = useState('')
    const [confirmationDate, setConfirmationDate] = useState('')
    const [designationDate, setDesignationDate] = useState('')

    const [regions, setRegions] = useState([])
    const [districts, setDistricts] = useState([])
    const [region, setRegion] = useState('')
    const [district, setDistrict] = useState('')
    const [role, setRole] = useState('')

    const [file, setFile] = useState('')
    const [subjects, setSubjects] = useState([])

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchRegions = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/regions`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setRegions(data.regions)
            }
        }
        catch (error) {
            setMessage('Failed to fetch regions. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchDistricts = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/districts/${region}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setDistricts(data.districts)
            }
        }
        catch (error) {
            setMessage('Failed to fetch districts. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')
        setErrorsFile('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    firstname,
                    middlename,
                    lastname,
                    email,
                    phone,
                    dob,
                    sex,
                    checkNumber,
                    tscNumber,
                    educationLevel,
                    salaryLevel,
                    subjectOne,
                    subjectTwo,
                    subjectThree,
                    subjectFour,
                    employmentDate,
                    confirmationDate,
                    designationDate,
                    region,
                    district,
                    role
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')

                // setFirstname('')
                // setMiddlename('')
                // setLastname('')
                // setEmail('')
                // setPhone('')
                // setDob('')
                // setSex('')
                // setCheckNumber('')
                // setTscNumber('')
                // setEducationLevel('')
                // setSalaryLevel('')
            }
        }
        catch (error) {
            setMessage('Failed to register staff. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while the data is being processed.')
        setVariant('info')
        setErrorsFile('')

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/employees/bulk`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
                if (data.errors) {
                    setErrorsFile(`${BASE_API_URL}/errors/file/${data.file.split('/').pop()}`)
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('Failed to upload file. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
        if (auth.user.role === "Admin") {
            fetchRegions()
        }
    }, [])

    useEffect(() => {
        if (region) {
            fetchDistricts()
        }
    }, [region])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>New Staff Member</p>
            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <p className='h4 mb-3 text-muted'>Basic Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='firstname'>First Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='firstname'
                                    required
                                    disabled={isLoading}
                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="middlename">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    id="middlename"
                                    disabled={isLoading}
                                    value={middlename}
                                    onChange={e => setMiddlename(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='lastname'>Last Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='lastname'
                                    required
                                    disabled={isLoading}
                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <input
                                    type="date"
                                    className="form-control rounded-0"
                                    id="dob"
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(dob)}
                                    onChange={e => setDob(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="sex">Sex</label>
                                <select
                                    className="form-select rounded-0"
                                    id='sex'
                                    required
                                    disabled={isLoading}
                                    value={sex}
                                    onChange={e => setSex(e.target.value)}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="educationLevel">Education Level</label>
                                <select
                                    className="form-select rounded-0"
                                    id='educationLevel'
                                    required
                                    disabled={isLoading}
                                    value={educationLevel}
                                    onChange={e => setEducationLevel(e.target.value)}
                                >
                                    <option value="">Select education level</option>
                                    <option value="Certificate">Certificate</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Degree">Degree</option>
                                    <option value="Masters">Masters</option>
                                    <option value="PhD">PhD</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <p className='h4 mb-3 text-muted'>Employment Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='checkNumber'>Check Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='checkNumber'
                                    required
                                    disabled={isLoading}
                                    value={checkNumber}
                                    onChange={e => setCheckNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='tscNumber'>TSC Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='tscNumber'
                                    required
                                    disabled={isLoading}
                                    value={tscNumber}
                                    onChange={e => setTscNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='salaryLevel'>Salary Level</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='salaryLevel'
                                    required
                                    disabled={isLoading}
                                    value={salaryLevel}
                                    onChange={e => setSalaryLevel(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='employmentDate'>Date of Employment</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='employmentDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(employmentDate)}
                                    onChange={e => setEmploymentDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='confirmationDate'>Date of Confirmation</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='confirmationDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(confirmationDate)}
                                    onChange={e => setConfirmationDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='designationDate'>Date of Designation</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='designationDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(designationDate)}
                                    onChange={e => setDesignationDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='subjectOne'>Subject One</label>
                                <select
                                    className='form-select'
                                    id='subjectOne'
                                    disabled={isLoading}
                                    value={subjectOne}
                                    onChange={e => setSubjectOne(e.target.value)}
                                >
                                    <option value=''>Select Subject</option>
                                    {
                                        subjects.map(subject => (
                                            <option key={subject.id} value={subject.id}>{subject.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='subjectTwo'>Subject Two</label>
                                <select
                                    className='form-select'
                                    id='subjectTwo'
                                    disabled={isLoading}
                                    value={subjectTwo}
                                    onChange={e => setSubjectTwo(e.target.value)}
                                >
                                    <option value=''>Select Subject</option>
                                    {
                                        subjects.map(subject => (
                                            <option key={subject.id} value={subject.id}>{subject.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='subjectThree'>Subject Three</label>
                                <select
                                    className='form-select'
                                    id='subjectThree'
                                    disabled={isLoading}
                                    value={subjectThree}
                                    onChange={e => setSubjectThree(e.target.value)}
                                >
                                    <option value=''>Select Subject</option>
                                    {
                                        subjects.map(subject => (
                                            <option key={subject.id} value={subject.id}>{subject.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='subjectFour'>Subject Four</label>
                                <select
                                    className='form-select'
                                    id='subjectFour'
                                    disabled={isLoading}
                                    value={subjectFour}
                                    onChange={e => setSubjecFour(e.target.value)}
                                >
                                    <option value=''>Select Subject</option>
                                    {
                                        subjects.map(subject => (
                                            <option key={subject.id} value={subject.id}>{subject.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <p className='h4 mb-3 text-muted'>Contact Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input
                                    type='email'
                                    className='form-control rounded-0'
                                    id='email'
                                    required
                                    disabled={isLoading}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='phone'>Phone (255...)</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='phone'
                                    required
                                    value={phone}
                                    onChange={(e) => {
                                        // test phonenumber with regex: 255(6|7)[0-9]{8}
                                        if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))) {
                                            setPhoneError(true)
                                        }
                                        else {
                                            setPhoneError(false)
                                        }
                                        setPhone(e.target.value)
                                    }
                                    }
                                />
                                {
                                    phoneError &&
                                    <small className="text-danger">Enter a valid phone number</small>
                                }
                            </div>
                        </div>
                    </div>

                    <RoleOffice
                        roles={["Admin"]}
                        element={
                            <>
                                <p className='h4 mb-3 text-muted'>Location Info</p>
                                <div className='row mb-3'>
                                <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='role'>Role</label>
                                            <select
                                                className='form-select'
                                                id='role'
                                                required
                                                disabled={isLoading}
                                                value={role}
                                                onChange={e => setRole(e.target.value)}
                                            >
                                                <option value=''>Select Role</option>
                                                <option value="RSEO">RSEO</option>
                                                <option value="DSEO">DSEO</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='region'>Region</label>
                                            <select
                                                className='form-select'
                                                id='region'
                                                required
                                                disabled={isLoading}
                                                value={region}
                                                onChange={e => setRegion(e.target.value)}
                                            >
                                                <option value=''>Select Region</option>
                                                {
                                                    regions.map(region => (
                                                        <option key={region.id} value={region.id}>{region.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='district'>District</label>
                                            <select
                                                className='form-select'
                                                id='district'
                                                required={role === "DSEO"}
                                                disabled={isLoading || role === "RSEO"}
                                                value={district}
                                                onChange={e => setDistrict(e.target.value)}
                                            >
                                                <option value=''>Select District</option>
                                                {
                                                    districts.map(district => (
                                                        <option key={district.id} value={district.id}>{district.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />

                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading || phoneError}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>

            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <>
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                        {
                            errorsFile &&
                            <div className="my-3">
                                <p className='text-danger'>
                                    The errors file appears here only once. If you do not download it now,
                                    you will not find it again!<br />
                                    Please download the file now and fix the errors, then re-upload it.
                                </p>
                                <a href={errorsFile} target='blank' download={true} className='btn btn-primary rounded-0'>
                                    <span className='me-2'>Download Errors File</span>
                                    <i className='bi bi-download'></i>
                                </a>
                            </div>
                        }
                    </>
                }

                <div className="d-flex align-items-center justify-content-between">
                    <p className='h4 text-muted'>Bulk Registration</p>
                    <a href={`${BASE_API_URL}/static/templates/mssis-template-staff-new.xlsx`} target='blank' download={true} className='btn btn-primary rounded-0 mb-3'>
                        <span className='me-2'>Download Template</span>
                        <i className='bi bi-download'></i>
                    </a>
                </div>
                <p className='mb-3'>
                    You can register multiple staff members at once by uploading an excel file.
                    <br />
                    First, download the template file, fill in the details and upload it here.
                </p>
                <div className='text-end mb-3'>
                    <form encType='multipart/form-data' onSubmit={handleUpload}>
                        <input
                            type='file'
                            className='form-control mb-3'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Uploading... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Upload
                                        </span>
                                        <i className='bi bi-upload'></i>
                                    </>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewStaff
