import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function Subjects() {
    const auth = useAuth()
    const user = auth.user

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [subjects, setSubjects] = useState([])
    const [fetchedSubjects, setFetchedSubjects] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <Link
                to={`/academics/subjects/${row.id}`}
                state={{ subject: row }}
                className='btn btn-sm btn-primary rounded-0'>
                <i className='bi bi-eye-fill fs-6 mx-1'></i>
            </Link>,
            sortable: false,
        }
    ]

    const handleSearch = (value) => {
        if (value.length < 1) {
            setSubjects(fetchedSubjects)
            return
        }

        const searchResults = fetchedSubjects.filter(subject => {
            return subject.name.toLowerCase().includes(value.toLowerCase())
        })

        setSubjects(searchResults)
    }

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSubjects(data.subjects)
                setFetchedSubjects(data.subjects)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Registered Subjects</p>
                <RoleOffice roles={["DSEO"]} element={
                    <Link to='/academics/subjects/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Register</span>
                        <i className='bi bi-plus-square-fill'></i>
                    </Link>}
                />
                <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                    <Link to='/academics/subjects/new/school' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Register</span>
                        <i className='bi bi-plus-square-fill'></i>
                    </Link>}
                />
            </div>
            <div className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Search subjects..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                    Search
                </button>
            </div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={subjects}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            noDataComponent={"No subjects found."}
                        />
                }
            </div>
        </div>
    )
}

export default Subjects
