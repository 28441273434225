import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function LogoAndStamps() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [stamp, setStamp] = useState("")
    const [logo, setLogo] = useState("")

    const updateLogo = async (e) => {
        e.preventDefault()
        setMessage('')

        const formData = new FormData()
        formData.append('logo', logo)

        try {
            const response = await fetch(`${BASE_API_URL}/school/logo`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token,
                },
                body: formData,
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
    }

    const updateStamp = async (e) => {
        e.preventDefault()
        setMessage('')

        const formData = new FormData()
        formData.append('stamp', stamp)

        try {
            const response = await fetch(`${BASE_API_URL}/school/stamp?role=headmaster`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token,
                },
                body: formData,
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
    }

    const fetchDetails = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            // fetch school logo
            const logo_res = await fetch(`${BASE_API_URL}/school/logo`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const logo_data = await logo_res.json()
            if (!logo_res.ok) {
                setMessage(logo_data.message)
                setVariant('warning')
            }
            else {
                setLogo(logo_data.logo)
            }

            // fetch headmaster stamp
            const stamp_res = await fetch(`${BASE_API_URL}/school/stamp?role=headmaster`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const stamp_data = await stamp_res.json()
            if (!stamp_res.ok) {
                setMessage(stamp_data.message)
                setVariant('warning')
            }
            else {
                setStamp(stamp_data.stamp)
            }
        }
        catch (error) {
            setMessage('Failed to fetch logo and stamp details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Logos and Stamps</p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="mb-3 p-2 shadow rounded-0">
                <div className="mb-5">
                    <p className="fw-bold text-muted fs-5">School Logo</p>
                    <form onSubmit={updateLogo}>
                        <div className="mx-auto text-center mb-2 border border-primary rounded-0 p-1">
                            {
                                logo ? (
                                    logo.toString().startsWith("/") ? (
                                        <img
                                            src={`${BASE_API_URL}${logo}`}
                                            alt='logo'
                                            className='img-fluid'
                                            width='200'
                                            height='170'
                                        />
                                    ) : (
                                        <img
                                            src={URL.createObjectURL(logo)}
                                            alt='logo'
                                            className='img-fluid'
                                            width='200'
                                            height='170'
                                        />
                                    )
                                ) : (
                                    <div className='m-2'>No Logo</div>
                                )
                            }
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <div className='me-2 bg-dark flex-grow-1'>
                                <input
                                    className='form-control rounded-0'
                                    type='file'
                                    id='logo'
                                    placeholder='Upload Logo'
                                    accept='image/*'
                                    onChange={(e) => setLogo(e.target.files[0])}
                                    required
                                />
                            </div>
                            <div className=''>
                                <button type='submit' className='btn btn-primary px-4 rounded-0 fw-bold' disabled={isLoading}>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mb-3">
                    <p className="fw-bold text-muted fs-5">Headmaster Stamp</p>
                    <form onSubmit={updateStamp}>
                        <div className="mx-auto text-center mb-2 border border-primary rounded-0 p-1">
                            {
                                stamp ? (
                                    stamp.toString().startsWith("/") ? (
                                        <img
                                            src={`${BASE_API_URL}${stamp}`}
                                            alt='stamp'
                                            className='img-fluid'
                                            width='200'
                                            height='170'
                                        />
                                    ) : (
                                        <img
                                            src={URL.createObjectURL(stamp)}
                                            alt='stamp'
                                            className='img-fluid'
                                            width='200'
                                            height='170'
                                        />
                                    )
                                ) : (
                                    <div className='m-2'>No Stamp</div>
                                )
                            }
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <div className='me-2 bg-dark flex-grow-1'>
                                <input
                                    className='form-control rounded-0'
                                    type='file'
                                    id='stamp'
                                    placeholder='Upload Stamp'
                                    accept='image/*'
                                    onChange={(e) => setStamp(e.target.files[0])}
                                    required
                                />
                            </div>
                            <div className=''>
                                <button type='submit' className='btn btn-primary px-4 rounded-0 fw-bold' disabled={isLoading}>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LogoAndStamps
