import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import TeachingProgressPie from '../../../components/charts/TeachingProgressPie'

function ViewSubjectTopics() {
    const auth = useAuth()
    const params = useParams()
    const formId = params.formId
    const subjectId = params.subjectId
    const location = useLocation()
    const form = location?.state.form
    const subject = location?.state.subject
    const school = location?.state.school

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [topics, setTopics] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Topic Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Progress',
            selector: row => row.progress,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`${row.id}`}
                    state={{ form: form, subject: subject, topic: row, school: school }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const fetchTopics = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/subject-topic/${formId}/${subjectId}`
            if (["DSEO"].includes(auth.user.role)) {
                url += `?school=${school.id}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setTopics(data.topics)
            }
        }
        catch (error) {
            setMessage('Failed to fetch topics. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTopics()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>Subject Topics</p>
                <Link
                    to={"report"}
                    state={{ form, subject, school }}
                    className='btn btn-primary btn-sm rounded-0 fw-bold'
                >
                    <span className="me-2">View Report</span>
                    <i className='bi bi-file-earmark-text-fill'></i>
                </Link>
            </div>

            {
                form && subject &&
                <p className='fw-bold px-1'>{form.name} {subject.name}</p>
            }

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className="shadow p-2 mb-3 row mx-0">
                            <div className="col-lg-6">
                                <p>
                                    <span className='fs-5 text-muted'>
                                        Topics Taught: <strong>{topics?.topics_taught || 0}</strong>
                                        <br />
                                        Topics In Progress: <strong>{topics?.topics_in_progress || 0}</strong>
                                        <br />
                                        Topics Not Taught: <strong>{topics?.topics_not_taught || 0}</strong>
                                    </span>
                                </p>
                            </div>
                            <div className="col-lg-6 my-0 py-0" style={{ maxHeight: "24rem" }}>
                                <TeachingProgressPie
                                    values={[topics?.topics_taught, topics?.topics_in_progress, topics?.topics_not_taught]}
                                />
                            </div>
                        </div>

                        <div className="shadow p-2 mb-3">
                            <p className="h5 text-muted">Select Topic</p>
                            <DataTable
                                columns={columns}
                                data={topics?.list}
                                highlightOnHover
                                noDataComponent={"No topics found."}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default ViewSubjectTopics
