import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'

function EditSchool() {
    const auth = useAuth()
    const params = useParams()
    const schoolId = params.id

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [name, setName] = useState('')
    const [reg_no, setRegNo] = useState('')
    const [location, setLocation] = useState('')

    const fetchSchool = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${schoolId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else{
                setName(data.school.name)
                setRegNo(data.school.reg_no)
                setLocation(data.school.location)
            }
        }
        catch (error) {
            setMessage('Failed to fetch school. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${schoolId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    reg_no,
                    location
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else{
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to update school. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchool()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Edit School Details</p>
            <div className='shadow p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleEdit}>
                    <p className='h4 mb-3 text-muted'>School Info</p>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name'>Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='reg_no'>Registration Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='reg_no'
                                    required
                                    disabled={isLoading}
                                    value={reg_no}
                                    onChange={e => setRegNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='location'>Location</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='location'
                                    required
                                    disabled={isLoading}
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Save
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditSchool
