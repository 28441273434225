import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { convertDateFormat } from '../../utils/date_conversions'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import RoleOffice from '../../components/offices/RoleOffice'

function ViewStudent() {
    const auth = useAuth()
    const params = useParams()
    const studentId = params.id

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [phoneError, setPhoneError] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const [classes, setClasses] = useState([])
    const [student, setStudent] = useState({})
    const [toDelete, setToDelete] = useState({})

    const fetchClases = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setClasses(data.classes)
            }
        }
        catch (error) {
            setMessage('An error occurred while fetching classes. Please refresh the page.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudent = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/students/${studentId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage('')
                setStudent(data)
            }
        } catch (error) {
            setMessage('An error occurred while fetching the student')
            setVariant('danger')
        }

        setIsLoading(false)
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/students/${studentId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ ...student, parentId: student.parent.id })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage('Student details updated successfully')
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('An error occurred while updating student details')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        setMessage('')
        setShowDialog(false)

        try {
            const response = await fetch(`${BASE_API_URL}/students/${studentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage('Failed to delete student. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClases()
        fetchStudent()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Student Details</p>
                <RoleOffice roles={["Headmaster"]} element={
                    <div className="d-flex align-items-center justify-content-between">
                        <button
                            className='btn btn-danger btn-sm fw-bold rounded-0 me-2'
                            onClick={() => {
                                setToDelete({})
                                setShowDialog(true)
                            }}
                        >
                            <span className="me-2">Delete</span>
                            <i className='bi bi-trash'></i>
                        </button>
                        <button
                            className='btn btn-primary btn-sm fw-bold rounded-0'
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            {
                                isEditing ?
                                    <>
                                        <span className="me-2">Cancel</span>
                                        <i className='bi bi-x-lg'></i>
                                    </> :
                                    <>
                                        <span className="me-2">Edit</span>
                                        <i className='bi bi-pencil-square'></i>
                                    </>
                            }
                        </button>
                    </div>
                } />
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    handleDelete()
                }}
                isLoading={isLoading}
                title='Delete Student'
                message={`Are you sure you want to delete the student?`}
            />

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className='shadow p-2 mb-4'>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <form onSubmit={handleUpdate}>
                                <p className='h4 mb-3 text-muted'>Basic Info</p>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='firstname'>
                                                First Name <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='firstname'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.firstname}
                                                onChange={
                                                    e => setStudent({ ...student, firstname: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="middlename">Middle Name</label>
                                            <input
                                                type="text"
                                                className="form-control rounded-0"
                                                id="middlename"
                                                disabled={isLoading || !isEditing}
                                                value={student.middlename}
                                                onChange={
                                                    e => setStudent({ ...student, middlename: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='lastname'>
                                                Last Name <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='lastname'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.lastname}
                                                onChange={
                                                    e => setStudent({ ...student, lastname: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="sex">
                                                Sex <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <select
                                                className="form-select rounded-0"
                                                id='sex'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.sex}
                                                onChange={
                                                    e => setStudent({ ...student, sex: e.target.value })
                                                }
                                            >
                                                <option value="">Select Sex</option>
                                                <option value="Female">Female</option>
                                                <option value="Male">Male</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="dob">
                                                Date of Birth <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control rounded-0"
                                                id="dob"
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={convertDateFormat(student.dob)}
                                                onChange={
                                                    e => setStudent({ ...student, dob: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='nationality'>
                                                Nationality <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='nationality'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.nationality}
                                                onChange={
                                                    e => setStudent({ ...student, nationality: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='tribe'>
                                                Tribe <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='tribe'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.tribe}
                                                onChange={
                                                    e => setStudent({ ...student, tribe: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <p className='h4 mb-3 text-muted'>Academic Info</p>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='school'>
                                                School Name <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='school'
                                                required
                                                disabled
                                                value={student.school ? student.school.name : ""}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='prems_number'>
                                                PREMS Number <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='prems_number'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.prems_number}
                                                onChange={
                                                    e => setStudent({ ...student, prems_number: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='admission_number'>
                                                Admission Number <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='admission_number'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.admission_number}
                                                onChange={
                                                    e => setStudent({ ...student, admission_number: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='form'>
                                                Class/Form <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <select
                                                className="form-select rounded-0"
                                                id='form'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.form}
                                                onChange={
                                                    e => setStudent({ ...student, form: e.target.value })
                                                }
                                            >
                                                <option value="">Select Student's Class</option>
                                                {
                                                    classes.map((cls, index) => (
                                                        <option key={index} value={cls.id}>{cls.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='stream'>
                                                Stream <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <select
                                                className="form-select rounded-0"
                                                id='stream'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={student.stream}
                                                onChange={e => setStudent({ ...student, stream: e.target.value })}
                                            >
                                                <option value="">Select Student's Class Stream</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                                <option value="F">F</option>
                                                <option value="G">G</option>
                                                <option value="H">H</option>
                                                <option value="I">I</option>
                                                <option value="J">J</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                {/* parent details */}
                                {
                                    student.parent &&
                                    <>
                                        <p className='h4 mb-3 text-muted'>Parent Info</p>
                                        <div className='row mb-3'>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='parentname'>
                                                        Full Name <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control rounded-0'
                                                        id='parentname'
                                                        required
                                                        disabled={isLoading || !isEditing}
                                                        value={student.parent.name}
                                                        onChange={
                                                            e => setStudent({
                                                                ...student,
                                                                parent: { ...student.parent, name: e.target.value }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='relationship'>
                                                        Relationship <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <select
                                                        className='form-select rounded-0'
                                                        id='relationship'
                                                        required
                                                        disabled={isLoading || !isEditing}
                                                        value={student.parent.relationship}
                                                        onChange={
                                                            e => setStudent({
                                                                ...student,
                                                                parent: { ...student.parent, relationship: e.target.value }
                                                            })
                                                        }
                                                    >
                                                        <option value=''>Select Relationship</option>
                                                        <option value='Father'>Father</option>
                                                        <option value='Mother'>Mother</option>
                                                        <option value='Guardian'>Guardian</option>
                                                        <option value='Other'>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='phone'>
                                                        Phone (255...) <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='phone'
                                                        required
                                                        disabled={isLoading || !isEditing}
                                                        value={student.parent.phone}
                                                        onChange={(e) => {
                                                            // test phonenumber with regex: 255(6|7)[0-9]{8}
                                                            if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))) {
                                                                setPhoneError(true)
                                                            }
                                                            else {
                                                                setPhoneError(false)
                                                            }
                                                            setStudent({
                                                                ...student,
                                                                parent: { ...student.parent, phone: e.target.value }
                                                            })
                                                        }
                                                        }
                                                    />
                                                    {
                                                        phoneError &&
                                                        <small className="text-danger">Enter a valid phone number</small>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='parentresidence'>
                                                        Residence <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control rounded-0'
                                                        id='parentresidence'
                                                        required
                                                        disabled={isLoading || !isEditing}
                                                        value={student.parent.residence}
                                                        onChange={
                                                            e => setStudent({
                                                                ...student,
                                                                parent: { ...student.parent, residence: e.target.value }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='parentoccupation'>
                                                        Occupation <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control rounded-0'
                                                        id='parentoccupation'
                                                        required
                                                        disabled={isLoading || !isEditing}
                                                        value={student.parent.occupation}
                                                        onChange={
                                                            e => setStudent({
                                                                ...student,
                                                                parent: { ...student.parent, occupation: e.target.value }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>
                                        <strong className='text-danger ms-1'>*</strong> indicates required fields
                                    </span>
                                    {
                                        isEditing &&
                                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading || phoneError}>
                                            {
                                                isLoading ?
                                                    <>Saving... <Loading /></> :
                                                    <>
                                                        <span className="me-2">
                                                            Save
                                                        </span>
                                                        <i className='bi bi-floppy2-fill'></i>
                                                    </>
                                            }
                                        </button>
                                    }
                                </div>
                            </form>

                        </div>
                    </>
            }
        </div >
    )
}

export default ViewStudent
