import React from 'react'
import './SubjectPerformance.css'
import './table-styles.css'

function SubjectPerformance({ summary }) {
    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                    <thead>
                        <tr>
                            <th colSpan={15 + summary.grades.length * 3}>
                                SUBJECT PERFORMANCE SUMMARY
                            </th>
                        </tr>
                        <tr className='align-middle'>
                            <th rowSpan={4}>S/N</th>
                            <th rowSpan={4}>SUBJECTS</th>
                            <th colSpan={1 + summary.grades.length * 3}>SUBJECT GRADE</th>
                            <th colSpan={7}>SUBJECT PERFORMANCE ANALYSIS</th>
                        </tr>
                        <tr className='align-middle'>
                            {
                                summary.grades.map((grade, index) => (
                                    <React.Fragment key={index}>
                                        <th colSpan={3}>{grade.grade}</th>
                                    </React.Fragment>
                                ))
                            }
                            <th rowSpan={2}>
                                <span className='vertical-text'>TOTAL</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>AVERAGE</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>GRADE</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>GPA</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>PASS</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>FAIL</span>
                            </th>
                            <th rowSpan={2}>
                                <span className='vertical-text'>RANK</span>
                            </th>
                            <th rowSpan={2}>COMPETENCE LEVEL</th>
                        </tr>
                        <tr className='align-middle'>
                            {
                                summary.grades.map((grade, index) => (
                                    <React.Fragment key={index}>
                                        <th>F</th>
                                        <th>M</th>
                                        <th>T</th>
                                    </React.Fragment>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            summary.ranks.map((rank, index) => (
                                <tr key={index} className='text-readable'>
                                    <td>{index + 1}</td>
                                    <td className='text-start'>{rank.subject.name.toUpperCase()}</td>
                                    {
                                        summary.grades.map((grade, index) => (
                                            <React.Fragment key={index}>
                                                <td>{rank.grades[grade.grade]["F"]}</td>
                                                <td>{rank.grades[grade.grade]["M"]}</td>
                                                <td>{rank.grades[grade.grade]["T"]}</td>
                                            </React.Fragment>
                                        ))
                                    }
                                    <td>{rank.total}</td>
                                    <td>{rank.average}</td>
                                    <td>{rank.grade}</td>
                                    <td>{rank.gpa}</td>
                                    <td>{rank.pass}</td>
                                    <td>{rank.fail}</td>
                                    <td>{rank.rank}/{summary.ranks.length}</td>
                                    <td className={`text-start p-0`}>
                                        <div className={`grade-${rank.grade} px-1`}>
                                            {rank.competance && `Grade ${rank.grade} (${rank.competance})`}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        {
                            summary.bottom_summary &&
                            <>
                                <tr className="fw-bold">
                                    <td colSpan={15 + summary.grades.length * 3}>EXAMINATION CENTRE RANKING</td>
                                </tr>
                                <tr className="fw-bold">
                                    <td className="text-start" colSpan={3}>EXAMINATION CENTRE REGION: </td>
                                    <td className="text-info" colSpan={3}>{summary.bottom_summary["region"]}</td>
                                    <td className="text-start" colSpan={6}>TOTAL PASSED CANDIDATES: </td>
                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["pass"]}</td>
                                    <td className="text-start" colSpan={8}>CENTRE CATEGORY: </td>
                                    <td className="text-info" colSpan={summary.grades.length * 3}>{summary.bottom_summary["category"]}</td>
                                </tr>
                                <tr className="fw-bold">
                                    <td className="text-start" colSpan={3}>EXAMINATION CENTRE DISTRICT: </td>
                                    <td className="text-info" colSpan={3}>{summary.bottom_summary["district"]}</td>
                                    <td className="text-start" colSpan={6}>EXAMINATION CENTRE GPA: </td>
                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["gpa"]}</td>
                                    <td className="text-start" colSpan={summary.grades.length * 3 - 12}>CENTRE POSITION: </td>
                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["position"]}</td>
                                    <td className="text-start" colSpan={3}>SCHOOL AVERAGE: </td>
                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["average"]}</td>
                                    <td className="text-info" colSpan={1}>{summary.bottom_summary["grade"]}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SubjectPerformance
