/* 
Show a list of exams that have subjects that current user teaches(is assigned to)
*/

import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'

function TeachingResultsExams() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [exams, setExams] = useState([])

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.schools,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.end_date,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <Link
                to={`/teaching/results/${row.id}`}
                state={{ exam: row }}
                className='btn btn-sm btn-primary rounded-0'>
                Select
            </Link>,
            sortable: false,
        }
    ]

    const handleSearch = (value) => { }

    const fetchExams = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/teacher`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setExams(data.exams)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exams. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExams()
    }, [])

    return (
        <div className='pt-2'>
            <div className='mb-3'>
                <p className='h3 fw-bold text-muted'>Select Exam</p>
                <p className='text-muted'>Only exams with subjects that you teach will appear here</p>
            </div>
            <div className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Filter exams..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0 d-flex align-items-center">
                    <span className="me-2">Filter</span>
                    <i className='bi bi-funnel-fill'></i>
                </button>
            </div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={exams}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            noDataComponent={"No exams done yet."}
                        />
                }
            </div>
        </div>
    )
}

export default TeachingResultsExams
