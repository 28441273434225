import React, { useEffect, useState } from 'react'
import RoleOffice from '../../../components/offices/RoleOffice'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../../components/loading/Loading'

function ApproveSubjectTopicCoverage() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [topics, setTopics] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Class',
            selector: row => row.form.name,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Subject',
            selector: row => row.subject.name,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        ["Headmaster", "Academic Master"].includes(auth.user.school.role) &&
            {
                name: 'Actions',
                cell: row => <div>
                    <button
                        className='btn btn-sm btn-primary rounded-0 fw-bold nowrap'
                        disabled={
                            row.status === "Approved" ||
                            (row.status === "Pending" && ["Academic Master"].includes(auth.user.school.role)) ||
                            (row.status === "Not Approved" && ["Headmaster"].includes(auth.user.school.role))
                        }
                        onClick={() => singAndApprove(row.id)}
                    >
                        <span className="me-2">Approve</span>
                        <i className='bi bi-patch-check-fill fs-6 mx-1'></i>
                    </button>
                </div>,
                button: true
            }
    ]

    const singAndApprove = async (topicId) => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/${topicId}/approve`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setVariant('warning')
            }
            else {
                fetchTopics()
                setVariant("success")
            }
            setMessage(data.message)
        }
        catch (error) {
            setMessage('Failed to sign and approve topic. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchTopics = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/approve`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setTopics(data.topics)
            }
        }
        catch (error) {
            setMessage('Failed to fetch topics. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTopics()
    }, [])

    return (
        <div className='pt-2'>
            <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                <p className='h3 fw-bold text-muted'>Approve Subject Topic Coverage</p>
            } />
            <RoleOffice roles={["Teacher"]} element={
                <p className='h3 fw-bold text-muted'>Subject Topic Coverage Approval Status</p>
            } />

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="p-2 shadow rounded-0">
                <p>
                    <strong><i>Not Approved</i></strong> means not approved by either academic master or headmaster
                    <br />
                    <strong><i>Pending</i></strong> means approved by academic master but waiting for headmaster
                </p>
                <DataTable
                    columns={columns}
                    data={topics}
                    highlightOnHover
                    progressPending={isLoading}
                    progressComponent={<div className='text-center'>Loading... <Loading /></div>}
                    noDataComponent={"No topics found."}
                />
            </div>
        </div>
    )
}

export default ApproveSubjectTopicCoverage
