import React from 'react'
import './NavBar.css'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { BASE_API_URL } from '../../utils/constants';

function NavBar() {
	const auth = useAuth()
	const user = auth.user
	const navigate = useNavigate()

	const toggleSideBar = () => {
		const toggle = document.getElementById('header-toggle');
		const nav = document.getElementById('nav-bar');
		const bodypd = document.getElementById('body-pd');
		const headerpd = document.getElementById('header');

		// Validate that all variables exist
		if (toggle && nav && bodypd && headerpd) {
			// show navbar
			nav.classList.toggle('show');
			// change icon
			toggle.classList.toggle('bi-x');
			// add padding to body
			bodypd.classList.toggle('body-pd');
			// add padding to header
			headerpd.classList.toggle('body-pd');
		}
	}

	return (
		<>
			<header className="header" id="header">
				<div className="header_toggle" onClick={toggleSideBar}>
					<i className='bi bi-list' id="header-toggle"></i>
				</div>
				<div className='d-flex align-items-center justify-content-end flex-fill me-4'>
					<span className='fw-bold m-1'>
						{
							user.school ?
								<>
									{user.school.name} Secondary School
								</> :
								<>
									{
										user.role === "Admin" ?
											<>
												Admin
											</>
											:
											<>
												DSEO Office
											</>
									}
								</>
						}
					</span>
					<div className='header_bell ms-3'>
						<i className='bi bi-bell-fill fs-2'>
							<span className="bell-badge"></span>
						</i>
					</div>
				</div>
				<div className="header_img text-center fw-bold fs-4 link" data-bs-toggle="collapse" data-bs-target="#userMenu">
					{
						user.profile ? (
							<img
								src={`${BASE_API_URL}${user.profile}`}
								alt='profile'
								className='rounded-circle'
							/>
						) :
							(
								<i className='bi bi-person-fill text-white'></i>
							)
					}
				</div>
			</header>
			<div className='collapse user_dropdown' id='userMenu'>
				<div className='nav_link link mt-2'>
					{
						user.school ?
							<>
								{
									user.school.role === "Headmaster" ?
										(
											user.sex === "Female" ? "Headmistress" : "Headmaster"
										)
										:
										user.school.role === "Academic Master" ?
											(
												user.sex === "Female" ? "Academic Mistress" : "Academic Master"
											)
											:
											user.school.role
								}
							</> :
							<>
								{user.role}
							</>
					}
					: {user.firstname} {user.lastname}
				</div>
				<Link to="/settings/profile" className='nav_link link'>
					<i className='bi bi-person nav_icon'></i>
					<span className='ms-2 nav_text'>My Profile</span>
				</Link>
				<div className='nav_link link' onClick={() => {
					auth.logout()
					navigate("/login")
				}}>
					<i className='bi bi-box-arrow-right nav_icon'></i>
					<span className='ms-2 nav_text'>Logout</span>
				</div>
			</div>
		</>
	)
}

export default NavBar
