import React from 'react'
import { useAuth } from '../../context/AuthContext'

function RoleOffice({ roles, element, error=null }) {
    const auth = useAuth()
    const user = auth.user

    // replace headmistress with headmaster and academic mistress with academic master
    let user_role = user.school?user.school.role:user.role
    if (user_role === 'Headmistress') user_role = 'Headmaster'
    if (user_role === 'Academic Mistress') user_role = 'Academic Master'

    const role_test = roles.includes(user_role)
    const school_role_test = user.school?roles.includes(user_role):false
    // if you want to check for class teacher's role, just pass the class name in the roles argument
    const class_teacher_test = roles.some(item => user.class_teacher.includes(item))  

    return (
        <>
        {
            user && (
                (role_test || school_role_test || class_teacher_test) ?
                <>
                    {element}
                </> :
                <>
                    {error}
                </>
            )
        }
        </>
    )
}

export default RoleOffice
