import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import { Link } from 'react-router-dom'

function Facilities() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [facilities, setFacilities] = useState({})

    const fetchFacilities = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/infrastructure`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setFacilities(data.details)
            }
        }
        catch (error) {
            setMessage("Failed to fetch facilities details")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacilities()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3">
                <p className='h3 fw-bold text-muted'>School Facilities</p>
                <small>A cummulative view of all facilities in all schools.</small>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <div className='mx-0'>
                            {
                                facilities ?
                                    <div className="row mx-0">
                                        {
                                            Object.keys(facilities).map((key, index) => (
                                                <Link to={key} className="col col-lg-3 me-3 mb-3 p-2 border rounded-0" key={index}>
                                                    <span className='fw-bold'>
                                                        {
                                                            key !== 'id' && (
                                                                <>
                                                                    {/* split key using _ and convert to title case */}
                                                                    <span className='text-dark'>
                                                                        {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}:
                                                                    </span> &nbsp;
                                                                    <span className="text-muted">{facilities[key]}</span>
                                                                </>
                                                            )
                                                        }
                                                    </span>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="text-center">
                                        No infrastructure info available
                                    </div>
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default Facilities
