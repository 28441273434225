import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import { useLocation, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Loading from '../../../components/loading/Loading'

function BareSubjectTopic() {
    const auth = useAuth()
    const param = useParams()
    const topicId = param.topicId
    const location = useLocation()
    const form = location?.state?.form
    const subject = location?.state?.subject

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [topic, setTopic] = useState(location?.state?.topic || {})

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Sub Topic',
            selector: row => row.name,
            sortable: true
        }
    ]

    const fetchTopic = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/bare/${topicId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setTopic(data.topic)
            }
        }
        catch (error) {
            setMessage('Failed to fetch topic details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTopic()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Registered Subject Topic</p>
            <p className='fw-bold px-1'>
                {form?.name} {subject?.name}
                <br />
                <span className="h4 text-muted">{topic?.name}</span>
            </p>

            <div className="shadow p-2 mb-3">
                <p className="h5 text-muted">Sub Topics</p>
                <DataTable
                    columns={columns}
                    data={topic.sub_topics}
                    highlightOnHover
                    progressPending={isLoading}
                    progressComponent={<div className='text-center'>Loading... <Loading /></div>}
                    noDataComponent={"No sub topics found."}
                />
            </div>
        </div>
    )
}

export default BareSubjectTopic
