import { createContext, useState, useContext } from "react"
import { jwtDecode } from 'jwt-decode'

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) =>{
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [token, setToken] = useState(localStorage.getItem("token"));  

  const login = (token_string) =>{
    const user = jwtDecode(token_string)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token_string)
    setUser(user)
    setToken(token_string)
  }

  const logout = () =>{
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setUser(null)
    setToken(null)
  }

  const isLoggedIn = () =>{
    try{
      const loggedinUser = JSON.parse(localStorage.getItem('user'))
      if (loggedinUser === null){
        return false
      }

      const isExpired = loggedinUser.exp * 1000 < Date.now()
      if (isExpired){
        return false
      }
      return true
    }
    catch(err){
      return false
    }
  }
  
  const updateProfile = (profile) =>{
    setUser({...user, profile: profile})
  }

  const contextValue = {
    user,
    token,
    login,
    logout,
    isLoggedIn,
    updateProfile
  }

  return(
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () =>{
  const contextValue = useContext(AuthContext)
  return contextValue
}
