import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'

function Classes() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [showDialog, setShowDialog] = useState(false)

    const [classes, setClasses] = useState([])
    const [toDelete, setToDelete] = useState({})

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.level,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <Link
                    to={`/academics/classes/${row.id}`}
                    state={{ form: row }}
                    className='btn btn-sm btn-primary rounded-0 me-2'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
                <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                    <button
                        onClick={() => {
                            setToDelete(row)
                            setShowDialog(true)
                        }}
                        className='btn btn-sm btn-danger rounded-0'>
                        <i className='bi bi-trash-fill fs-6 mx-1'></i>
                    </button>}
                />
            </div>,
            sortable: false,
        }
    ]

    const fetchClasses = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setClasses(data.classes)
                setMessage("")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const deleteClass = async (id) => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes/school/${id}`, {
                method: 'DELETE',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setMessage(data.message)
                setVariant("success")
                fetchClasses()
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Registered Classes</p>
                <RoleOffice roles={["DSEO", "Headmaster", "Academic Master"]} element={
                    <Link to='/academics/classes/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Register</span>
                        <i className='bi bi-plus-square-fill'></i>
                    </Link>}
                />
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    deleteClass(toDelete.id)
                    setShowDialog(false)
                }}
                isLoading={isLoading}
                title="Delete Class"
                message={`All students and results data will be lost! Are you sure you want to delete ${toDelete.name} class?`}
            />

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={classes}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            noDataComponent={"No classes registered yet."}
                        />
                }
            </div>
        </div>
    )
}

export default Classes
