import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { convertDateFormat } from '../../utils/date_conversions'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'

function NewExam() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('sample')
    const [variant, setVariant] = useState('success')

    const [type, setType] = useState('') // internal or external
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [involvedClasses, setInvolvedClasses] = useState([])
    const [involvedSubjects, setInvolvedSubjects] = useState([])
    const [involvedSchools, setInvolvedSchools] = useState([])

    const [subjects, setSubjects] = useState([])
    const [allSubjects, setAllSubjects] = useState([])
    const [schools, setSchools] = useState([])
    const [grades, setGrades] = useState([
        {
            grade: '',
            score: '',
            points: '',
            remark: '',
            indicator: 'success'
        }
    ])

    const validateGrades = () => {
        for (let i = 0; i < grades.length; i++) {
            // check that no two grades have the same minimum score
            for (let j = i + 1; j < grades.length; j++) {
                if (grades[i].score === grades[j].score) {
                    setMessage('Two grades cannot have the same minimum score.')
                    setVariant('warning')
                    return false
                }
            }

            // check that the minimum score is a number
            if (isNaN(grades[i].score)) {
                setMessage('Minimum score must be a number.')
                setVariant('warning')
                return false
            }

            // check that the minimum score is not negative or greater than 100
            if (grades[i].score < 0 || grades[i].score > 100) {
                setMessage('Minimum score cannot be negative or greater than 100.')
                setVariant('warning')
                return false
            }

            return true
        }
    }

    const schools_columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name:
                <div>
                    <input
                        type='checkbox'
                        className='me-1'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setInvolvedSchools(schools.map(school => school.id))
                            } else {
                                setInvolvedSchools([])
                            }
                        }}
                    />
                    <span>({involvedSchools.length})</span>
                </div>,
            cell: row => <button
                type='button'
                className={`btn btn-sm rounded-0 ${involvedSchools.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => {
                    if (involvedSchools.includes(row.id)) {
                        setInvolvedSchools(involvedSchools.filter(schoolId => schoolId !== row.id))
                    } else {
                        setInvolvedSchools([...involvedSchools, row.id])
                    }
                }}>
                {involvedSchools.includes(row.id) ? 'Selected' : 'Select'}
            </button>,
            sortable: false,
        }
    ]

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/core-form-subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSubjects(data.core_subjects)
                // set involved subjects as a list of subjects that are to be selected for each sepcific form
                // somthing like this {"form_id": [subject_id, subject_id, ...], "form_id": [subject_id, subject_id, ...]}
                const involvedSubs = {}
                data.core_subjects.forEach(sub => {
                    involvedSubs[sub.form.id] = []
                })
                setInvolvedSubjects(involvedSubs)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchAllSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setAllSubjects(data.subjects)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSchools = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            setMessage('Failed to fetch schools. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        if (!validateGrades()) {
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/exams`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    type,
                    name,
                    startDate,
                    endDate,
                    involvedClasses,
                    involvedSubjects,
                    involvedSchools,
                    grades
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('Failed to create exam. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
        fetchAllSubjects()

        if (auth.user.role == "DSEO") {
            fetchSchools()
        }
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Create Exam</p>
            <div className='shadow p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='type' className='form-label'>Exam Type</label>
                                <select
                                    className='form-select rounded-0'
                                    id='type'
                                    required
                                    disabled={isLoading}
                                    value={type}
                                    onChange={e => setType(e.target.value)}
                                >
                                    <option value=''>Select Exam Type</option>
                                    <option value='internal'>Internal</option>
                                    <RoleOffice
                                        roles={["DSEO"]}
                                        element={
                                            <option value='external'>External</option>
                                        }
                                    />
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name' className='form-label'>Exam Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    placeholder='Mid Term Exam'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='start_date' className='form-label'>Start Date</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='start_date'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(startDate)}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='end_date' className='form-label'>End Date</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='end_date'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(endDate)}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <RoleOffice
                        roles={["DSEO"]}
                        element={
                            <div className="mt-3">
                                <p>
                                    <span className="me-2 fw-bold">
                                        Select schools involved in this exam.
                                    </span>
                                </p>
                                <DataTable
                                    columns={schools_columns}
                                    data={schools}
                                    noDataComponent='No schools found'
                                />
                            </div>
                        }
                    />

                    <div className="row mx-0 mt-3">
                        <p>
                            <span className="me-2 fw-bold">Select Classes/Forms and their subjects involved in this exam.</span>
                        </p>
                        {
                            subjects.map((sub, index) => (
                                <div key={index} className="col-12 col-lg-auto mb-2 me-2 px-2 py-1 border rounded-0">
                                    <input
                                        type="checkbox"
                                        name="classes"
                                        id={sub.form.id}
                                        className='form-checkbox rounded-0 mb-0 me-2'
                                        value={sub.id}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setInvolvedClasses([...involvedClasses, sub.form.id])
                                            }
                                            else {
                                                setInvolvedClasses(involvedClasses.filter(ic => ic !== sub.form.id))
                                            }
                                        }}
                                    />
                                    <label htmlFor={sub.form.id} className='form-label mb-0'>
                                        {sub.form.name} &nbsp;
                                        <span
                                            className='text-primary'
                                            onClick={() => {
                                                if (involvedSubjects[sub.form.id].length === allSubjects.length) {
                                                    setInvolvedSubjects({
                                                        ...involvedSubjects,
                                                        [sub.form.id]: []
                                                    })
                                                }
                                                else {
                                                    setInvolvedSubjects({
                                                        ...involvedSubjects,
                                                        [sub.form.id]: allSubjects.map(s => s.id)
                                                    })
                                                }
                                            }}
                                        >
                                            Select All ({involvedSubjects[sub.form.id].length})
                                        </span>
                                    </label>

                                    <div className="row mx-0 mt-2">
                                        {
                                            allSubjects && allSubjects.map((s, index) => (
                                                <div key={index} className="col-12 col-lg-auto mb-2 me-2 px-2 py-1 border rounded-0">
                                                    <input
                                                        type="checkbox"
                                                        name="subjects"
                                                        id={`${sub.form.id}-${s.id}`}
                                                        className='form-checkbox rounded-0 mb-0 me-2'
                                                        disabled={!involvedClasses.includes(sub.form.id)}
                                                        checked={involvedSubjects[sub.form.id].includes(s.id)}
                                                        value={s.id}
                                                        onChange={e => {
                                                            if (e.target.checked) {
                                                                setInvolvedSubjects({
                                                                    ...involvedSubjects,
                                                                    [sub.form.id]: [...involvedSubjects[sub.form.id], s.id]
                                                                })
                                                            }
                                                            else {
                                                                setInvolvedSubjects({
                                                                    ...involvedSubjects,
                                                                    [sub.form.id]: involvedSubjects[sub.form.id].filter(is => is !== s.id)
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={`${sub.form.id}-${s.id}`} className='form-label mb-0'>
                                                        {s.name}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="">
                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }
                    </div>

                    <div className="mt-3">
                        <p className="mx-2 fw-bold">
                            Create grades specific for this exam.<br />
                            <small>Leave blank to use default grades. (Not recommended)</small>
                        </p>

                        {
                            grades.map((grade, index) => (
                                <div className='row mx-0 mb-3 border p-1' key={index}>
                                    <div className='col-lg-2 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='grade' className='form-label'>Grade</label>
                                            <input
                                                type='text'
                                                id='grade'
                                                className='form-control rounded-0'
                                                placeholder='A'
                                                value={grade.grade}
                                                onChange={e => {
                                                    const newGrades = [...grades]
                                                    newGrades[index].grade = e.target.value
                                                    setGrades(newGrades)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='score' className='form-label'>Minimum Score</label>
                                            <input
                                                type='text'
                                                id='score'
                                                className='form-control rounded-0'
                                                placeholder='80'
                                                value={grade.score}
                                                onChange={e => {
                                                    const newGrades = [...grades]
                                                    newGrades[index].score = e.target.value
                                                    setGrades(newGrades)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='points' className='form-label'>Points</label>
                                            <input
                                                type='text'
                                                id='points'
                                                className='form-control rounded-0'
                                                placeholder='1'
                                                value={grade.points}
                                                onChange={e => {
                                                    const newGrades = [...grades]
                                                    newGrades[index].points = e.target.value
                                                    setGrades(newGrades)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='remark' className='form-label'>Remark</label>
                                            <input
                                                type='text'
                                                id='remark'
                                                className='form-control rounded-0'
                                                placeholder='Excellent'
                                                value={grade.remark}
                                                onChange={e => {
                                                    const newGrades = [...grades]
                                                    newGrades[index].remark = e.target.value
                                                    setGrades(newGrades)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='indicator' className='form-label'>
                                                <span className='me-2'>Indicator</span>
                                                <span className={`badge bg-${grade.indicator} rounded-0`}>{grade.remark}</span>
                                            </label>
                                            <select
                                                className='form-select rounded-0'
                                                id='indicator'
                                                value={grade.indicator}
                                                onChange={e => {
                                                    const newGrades = [...grades]
                                                    newGrades[index].indicator = e.target.value
                                                    setGrades(newGrades)
                                                }}
                                            >
                                                <option value='success'>Green</option>
                                                <option value='primary'>Blue</option>
                                                <option value='info'>Light Blue</option>
                                                <option value='warning'>Yellow</option>
                                                <option value='danger'>Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="d-flex align-items-center justify-content-end mb-3">
                            <button
                                type='button'
                                className='btn btn-secondary px-4 rounded-0 me-2'
                                onClick={() => setGrades([
                                    ...grades, {
                                        grade: '',
                                        score: '',
                                        remark: '',
                                        indicator: 'success'
                                    }
                                ])}
                            >
                                <span className='me-2'>Add Grade</span>
                                <i className='bi bi-plus-circle'></i>
                            </button>
                        </div>
                    </div>

                    <div className="">
                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }
                    </div>

                    <div className="d-flex flex-wrap align-items-end justify-content-between mb-3 p-2">
                        <div className='flex-grow-1'>
                            <div className="fw-bold mb-2 me-1">Schools Selected: {involvedSchools.length}</div>
                            <div className="fw-bold mb-2 me-1">Classes Selected: {involvedClasses.length}</div>
                            <div className="fw-bold mb-0 me-1">Subjects Selected:</div>
                            <div className="row mb-2">
                                {
                                    Object.keys(involvedSubjects).map((subject, index) => (
                                        <div className="col-12 col-lg-2 fw-bold">Form {index + 1}: {involvedSubjects[subject].length}</div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="flex-grow-1 text-end">
                            <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                {
                                    isLoading ?
                                        <>Creating... <Loading /></> :
                                        <>
                                            <span className="me-2">
                                                Create
                                            </span>
                                            <i className='bi bi-floppy2-fill'></i>
                                        </>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewExam
