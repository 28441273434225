import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function NewClass() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [name, setName] = useState("")
    const [level, setLevel] = useState("")
    const [subjects, setSubjects] = useState([])
    const [coreSubjects, setCoreSubjects] = useState([])
    const [classes, setClasses] = useState([])
    const [teachers, setTeachers] = useState([])
    const [teacher, setTeacher] = useState([{}])

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSubjects(data.subjects)
            }
        } catch (error) {
            setMessage("Failed to fetch subjects. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchClasses = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            setMessage("Failed to fetch classes. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchTeachers = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setTeachers(data.employees)
            }
        } catch (error) {
            setMessage("Failed to fetch classes. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        setMessage('')

        if (coreSubjects.length < 3 && auth.user.role !== "DSEO") {
            setMessage("Please select at least 3 core subjects.")
            setVariant('warning')
            setIsLoading(false)
            return
        }

        try {
            let url = `${BASE_API_URL}/classes`
            if (auth.user.role === "DSEO") {
                url += "/bare"
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    level,
                    coreSubjects,
                    teacher
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        } catch (error) {
            setMessage("Failed to register class. Please try again.")
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
        fetchClasses()
        fetchTeachers()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register Class</p>
            <div className='shadow p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name' className='form-label'>Class Name</label>
                                <select
                                    className='form-select'
                                    id='name'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}>
                                    <option value=''>Select Class</option>
                                    {
                                        classes && classes.map((form, index) => (
                                            <option key={index} value={form.id}>{form.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            auth.user.role === "DSEO" &&
                            <div className='col-lg-4 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='level' className='form-label'>Class Level</label>
                                    <select
                                        className='form-select'
                                        id='level'
                                        required
                                        disabled={isLoading}
                                        value={level}
                                        onChange={e => setLevel(e.target.value)}>
                                        <option value=''>Select Level</option>
                                        <option value='O Level'>O Level</option>
                                        <option value='A Level'>A Level</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        {
                            auth.user.role !== "DSEO" &&
                            <>
                                <div className="row">
                                    {
                                        teacher && teacher.map((teach, index) => (
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='teacher' className='form-label'>Class Teacher {index + 1}</label>
                                                    <select
                                                        className='form-select'
                                                        id='teacher'
                                                        required
                                                        disabled={isLoading}
                                                        value={teach}
                                                        onChange={e => {
                                                            const newValue = e.target.value
                                                            const updatedTeachers = teacher.map((t, i) => (i === index ? newValue : t))
                                                            setTeacher(updatedTeachers)
                                                        }}>
                                                        <option value=''>Select Class Teacher</option>
                                                        {
                                                            teachers && teachers.map((teacher, i) => (
                                                                <option key={i} value={teacher.id}>
                                                                    {teacher.firstname} {teacher.lastname}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='mb-3 text-end'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary btn-sm px-2 rounded-0 me-2'
                                        onClick={() => setTeacher([
                                            ...teacher, {}
                                        ])}
                                    >
                                        <span className='me-2'>Add Teacher</span>
                                        <i className='bi bi-plus-circle'></i>
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                    {
                        auth.user.role !== "DSEO" &&
                        <>
                            <div className="my-3">
                                <p className='mb-3'>
                                    Class Subjects
                                    {/* <br />
                                    <small className='text-muted'>
                                        These are subjects that all students in this class must study.
                                    </small> */}
                                </p>
                                <div className="row mx-1">
                                    <div className="col-12 col-lg-auto me-2 mb-2">
                                        <input
                                            type="checkbox"
                                            id="check_all"
                                            className='form-radio mb-0 me-2'
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    setCoreSubjects(subjects.map(subject => subject.id))
                                                } else {
                                                    setCoreSubjects([])
                                                }
                                            }}
                                        />
                                        <label htmlFor="check_all" className='form-label text-primary'>
                                            Select All ({coreSubjects.length})
                                        </label>
                                    </div>
                                    {
                                        subjects && subjects.map((subject, index) => (
                                            <div key={index} className="col-12 col-lg-auto me-2 mb-2 px-2 py-1 border rounded-0">
                                                <input
                                                    type="checkbox"
                                                    name="subjects"
                                                    id={subject.id}
                                                    className='form-radio mb-0 me-2'
                                                    value={subject.id}
                                                    checked={coreSubjects.includes(subject.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCoreSubjects([...coreSubjects, subject.id])
                                                        } else {
                                                            setCoreSubjects(coreSubjects.filter(id => id !== subject.id))
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={subject.id} className='mb-0 form-label'>
                                                    {subject.name}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="">
                                {
                                    message &&
                                    <div className={`alert alert-${variant} p-2`} role="alert">
                                        {message}
                                    </div>
                                }
                            </div>
                        </>
                    }
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewClass
