import React, { useState } from 'react'
import logo from '../../assets/mssis.png'
import Loading from '../../components/loading/Loading'
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'

function PasswordResetNew() {
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [code, setCode] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        if (password !== confirm) {
            setMessage('Passwords do not match.')
            setVariant('danger')
            setIsLoading(false)
            return
        }

        try {
            const resp = await fetch(`${BASE_API_URL}/profile/password/reset?username=${username}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code, password, confirm })
            })

            const data = await resp.json()
            if (!resp.ok) {
                setMessage(data.message)
                setVariant('danger')
                setIsLoading(false)
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        setIsLoading(false)
    }

    return (
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center vh-100">
                <div className="col-12 col-lg-6 text-center mb-3">
                    <img src={logo} alt="MSSIS Logo" style={{ width: "120px" }} className="img-fluid" />
                    <br />
                    <h1 className="text-muted h3">
                        Modern Secondary Schools Information System (MSSIS)
                    </h1>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <h2 className="text-muted h4">Password reset</h2>
                    <small className="mb-3">Enter your username, the code you received and a new password.</small>
                    <div className="my-2 text-center">
                        {
                            message && <span className={`text-${variant}`}>{message}</span>
                        }
                    </div>
                    <form className="mx-2" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label fw-bold">Username</label>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="form-control rounded-0"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="code" className="form-label fw-bold">Code</label>
                            <input
                                type="text"
                                name="code"
                                id="code"
                                className="form-control rounded-0"
                                required
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label fw-bold">New Password</label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control rounded-0"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirm" className="form-label fw-bold">Confirm Password</label>
                            <input
                                type="password"
                                name="confirm"
                                id="confirm"
                                className="form-control rounded-0"
                                required
                                value={confirm}
                                onChange={(e) => setConfirm(e.target.value)}
                            />
                        </div>
                        <div className="mb-2 text-end text-primary">
                            <Link to={"/password-reset"}>Request Code</Link>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary w-100 rounded-0 fw-bold">
                                {
                                    isLoading ?
                                        <>
                                            Updating... <Loading />
                                        </> :
                                        <>
                                            Update
                                        </>
                                }
                            </button>
                        </div>
                        <Link to={"/login"} className="text-primary">Back to login</Link>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetNew
